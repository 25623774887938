<template>
  <div>
    <vs-popup
      fullscreen
      class="holamundo dcr-particular-popup"
      title="PARTICULARS LIST"
      :active.sync="allParticularsIDPopup"
    >
      <ag-grid-vue
        :gridOptions="gridOptions"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :rowData="allParticulars"
        rowSelection="rowSelection"
        :suppressRowClickSelection="true"
        :singleClickEdit="true"
        :animateRows="true"
        :floatingFilter="false"
        :paginationPageSize="paginationPageSize"
        :pagination="true"
        :suppressPaginationPanel="true"
        :suppressRowTransform="true"
      ></ag-grid-vue>
      <vs-pagination
        :total="totalPages"
        :max="maxPageNumbers"
        v-model="currentPage"
      />
      <!-- :suppressRowTransform="suppressRowTransform" -->
    </vs-popup>
  </div>
</template>

<script>
import Vue from "vue";
import { AgGridVue } from "ag-grid-vue";
import "@/assets/scss/vuesax/extraComponents/agGridStyleOverride.scss";
import dcrService from "@/services/dcrService.js";

import moment from "moment";
export default Vue.extend({
  props: {
    DCRData: {
      type: Object,
    },
  },
  components: {
    AgGridVue,
  },
  data() {
    return {
      TableData: [
        {
          description: "test",
          dataArr: [
            {
              dcr_particular_id: 4,
              budget_id: 1,
              dcr_id: 2,
              purchase_id: null,
              dcr_particular_name: "Second Particular",
              per_day: 0,
              shift: 2,
              unit_cost: 300,
              extra_cost: 120,
              extra_cost_reason: "Repair",
              total_cost: 720,
              description: null,
              dcr_particular_isActive: 1,
              dcr_particular_created_by: 2,
              dcr_particular_created_date: 1616134548,
              dcr_particular_modified_by: 2,
              dcr_particular_modified_date: 1616134548,
              dcr_date: "2021-03-19",
              dcr_description: null,
            },
            {
              dcr_particular_id: 4,
              budget_id: 1,
              dcr_id: 2,
              purchase_id: null,
              dcr_particular_name: "Second",
              per_day: 0,
              shift: 2,
              unit_cost: 300,
              extra_cost: 120,
              extra_cost_reason: "Repair",
              total_cost: 720,
              description: null,
              dcr_particular_isActive: 1,
              dcr_particular_created_by: 2,
              dcr_particular_created_date: 1616134548,
              dcr_particular_modified_by: 2,
              dcr_particular_modified_date: 1616134548,
              dcr_date: "2021-03-19",
              dcr_description: null,
            },
          ],
          date: "22/03/2021",
        },
      ],
      params: null,
      allParticularsIDPopup: false,
      budgetDetail: [],
      budgetList: [],
      ParticularsData: [],
      totalPages: 0,
      allParticulars: [],
      popupActive: false,
      gridApi: null,
      searchQuery: "",
      gridOptions: {},
      maxPageNumbers: 7,
      budgetLogs: [],
      defaultColDef: {
        sortable: true,
        editable: false,
        resizable: true,
        suppressMenu: true,
      },
      columnDefs: [],
    };
  },

  beforeMount: function () {
    // this.suppressRowTransform = true;
    this.columnDefs = [
      {
        headerName: "DCR Description",
        field: "dcr_description",
        filter: false,
        width: 300,
      },
      {
        headerName: "Vendor Name",
        field: "dcr_particular_name",
        filter: false,
        width: 300,
        suppressSizeToFit: true,
      },
      {
        headerName: "Per Day",
        field: "per_day",
        filter: false,
        width: 150,
      },
      {
        headerName: "Shift",
        field: "shift",
        filter: false,
        width: 150,
      },
      {
        headerName: "Unit Cost",
        field: "unit_cost",
        filter: false,
        width: 150,
      },
      {
        headerName: "Extra Cost",
        field: "extra_cost",
        filter: false,
        width: 150,
        suppressSizeToFit: true,
      },
      {
        headerName: "Reason",
        field: "extra_cost_reason",
        filter: false,
        width: 350,
        suppressSizeToFit: true,
      },
      {
        headerName: "Final Cost",
        field: "total_cost",
        filter: false,
        width: 200,
        suppressSizeToFit: true,
      },
      {
        headerName: "DCR Date",
        field: "dcr_date",
        filter: false,
        width: 300,
        suppressSizeToFit: true,
      },
    ];
  },
  mounted() {
    this.gridApi = this.gridOptions.api;

    eventBus.$on("IsParticularPopup", (e) => {
      this.allParticulars = [];
      this.$vs.loading();
      this.allParticularsIDPopup = true;

      setTimeout(() => {
        if (this.DCRData.hasOwnProperty("project_Id")) {
          this.getAllParticularsData(
            this.DCRData.project_Id,
            this.DCRData.dcr_Ids
          );
        } else {
          // this.$vs.notify({
          //   title: "Somthing Went Wrong 88",
          //   text: "No data found",
          //   iconPack: "feather",
          //   icon: "check_box",
          //   color: "warning",
          // });
          this.$vs.loading.close();
        }
      }, 300);
    });
  },
  beforeDestroy() {
    // let _id = document.getElementById(
    //   "popmodalView" + this.
    // );
    // _id.setAttribute("style", "display: none;");
  },
  watch: {
    "$store.state.windowWidth"(val) {
      if (val <= 576) {
        this.maxPageNumbers = 4;
        this.gridOptions.columnApi.setColumnPinned("email", null);
      } else this.gridOptions.columnApi.setColumnPinned("email", "left");
    },
  },
  computed: {
    paginationPageSize() {
      if (this.gridApi) return this.gridApi.paginationGetPageSize();
      else return 100;
    },
    currentPage: {
      get() {
        if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
        else return 1;
      },
      set(val) {
        if (val) {
          //   this.getDepartmentList(val);
          return val;
        } else return 1;
      },
    },
  },
  methods: {
    getAllParticularsData(project_ID, DCR_ID) {
      dcrService
        .getAllParticulars(project_ID, DCR_ID)
        .then((response) => {
          const { data } = response;
          if (data["data"] && data.Status) {
            // let allParticularsTepmData = data.data;
            this.allParticulars = data.data;

            // this.allParticulars = this.allParticulars.filter(
            //   (thing, index, self) =>
            //     index ===
            //     self.findIndex(
            //       (t) =>
            //         t.dcr_description === thing.dcr_description &&
            //         t.dcr_date === thing.dcr_date
            //     )
            // );
          } else {
            this.$vs.notify({
              title: "Somthing Went Wrong 55",
              text: data.Message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
          this.$vs.loading.close();
        })
        .catch((error) => {
          this.$vs.loading.close();
          this.$vs.notify({
            title: "Error!",
            text: error.Message,
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
  },
});
</script>

<style></style>
